import React from 'react'

import Layout from '../../components/layout'
import FeatureLeft from '../../components/featureLeft'
import { Container, Row, Image, Col } from 'react-bootstrap'
import { Section, SectionGray, SingleSection, SingleSectionGray } from '../../components/sections'

import { faPeopleCarry, faSearch, faClipboardList, faCheck, faMoneyBillAlt, faGavel, faStopwatch } from '@fortawesome/free-solid-svg-icons'

import risk2 from '../../images/app/risk2.jpg'
import riskMatrix from '../../images/app/Risk-Matrix.jpg'

const Rischi = () => (
<Layout frame='appNav'>

    <Section id='rischi'>
        <Container>
            <Row>
                <Col className='text-center heading-section'>
                    <h3>Gestione dei rischi</h3>
                    <p>Il rischio è definito come un fattore che provoca incertezza.
                        Se è positivo genera delle opportunità, mentre se è nagativo crea delle minacce. Esso deve essere valutato in relazione alla
                        combinazione fra la probabilità d’insorgenza e l’impatto che genera qualora dovesse verificarsi.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col className='text-center'>
                    <Image src={risk2} fluid />
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <FeatureLeft
                  md={6} icon={faSearch} title='Risk identification' desc='Per costruire un profilo di rischio adeguato l’organizzazione deve essere
                            consapevole dei rischi che affronta.'
                />

                <FeatureLeft md={6} icon={faClipboardList} title='Risck assesment' desc='L’obiettivo finale di questo strumento è quello di individuare i rischi che hanno una maggiore criticità.' />

            </Row>
            <Row>
                <FeatureLeft md={6} icon={faPeopleCarry} title='Risk adressment' desc='Per ogni tipologia di rischio, viene definito il controllo specifico che deve essere effettuato al fine di ridurre la criticità.' />

                <FeatureLeft md={6} icon={faCheck} title='Risk review and report' desc="Questa fase è parte integrante del SCI e serve a comprovare l'effettiva messa in atto del controllo stabilito per ridurre l'impatt odel rischio." />
            </Row>
        </Container>
    </Section>

    <SectionGray id='identificazione'>
        <Container>
            <Row>
                <Col className='text-center heading-section'>
                    <h3>Risk identification</h3>
                    <p>Per costruire un profilo di rischio adeguato l’organizzazione deve essere consapevole
                        dei rischi
                        che affronta. L’identificazione del rischio può essere separata in due sotto-fasi:
                    </p>
                    <p>
                        L'identificazione iniziale del rischio è indicata per le nuove organizzazioni oppure
                        per le organizzazioni che non hanno
                        ancora definito i rischi in modo strutturato.
                    </p>
                    <p>
                        L'identificazione del rischio continuativa è necessaria per identificare ulteriori
                        rischi non ancora analizzati, effettuare
                        modifiche a quelli già presenti oppure eliminare quelli che non sono più rilevanti per
                        l’organizzazione.
                    </p>
                    <p>I rischi concernono le tre aree di intervento SCI e posso essere classificati nelle
                        seguenti
                        tre categorie:
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                    <FeatureLeft
                      icon={faStopwatch} title='Rischi di efficienza operativa (RO)' desc="Hanno impatto sull'operatività, causano un rallentamento nell'esecuzione dei
                                                        processi, creano problemi nel raggiungimento degli obiettivi di processo e potenzialmente minano
                                                        la protezione del patrimonio dell’Istituto o del Fondo amministrato dalla Cassa di  compensazione."
                    />

                    <FeatureLeft
                      icon={faMoneyBillAlt} title='Rischi di rendicontazione (RR)' desc='Questi rischi hanno un impatto sui conti dell’Istituto e possono anche mettere
                                            in discussione l’attendibilità dei dati contabili. Questo è un aspetto ritenuto fondamentale
                                            considerando il carattere pubblico dell’attività svolta dall’Istituto.'
                    />

                    <FeatureLeft
                      icon={faGavel} title='Rischi di compliance (RC)' desc='Questi rischi creano delle non conformità delle procedure dell’Istituto in
                                                                    relazioneal rispetto delle direttive e delle normative legali vigenti.'
                    />

            </Row>
        </Container>

    </SectionGray>

    <Section id='valutazione'>
        <Container>
            <Row>
                <Col className='text-center heading-section'>
                    <h3>Risk assessment</h3>
                    <p>Per fare una valutazione è necessario implementare un processo chiaro e strutturato che
                        sia in grado di considerare sia la probabilità d’insorgenza che l’impatto di ogni
                        rischio analizzato.
                        Il risultato ottenuto dev’essere in grado di facilitare il monitoraggio e
                        l’identificazione dei rischi prioritari. In questa fase è necessaria l’elaborazione di
                        una matrice di tollerabilità la quale si base su di una chiara definizione dei
                        parametri di valutazione della probabilità e dell’impatto del rischio.
                    </p>
                    <p>
                        L’obiettivo finale di questo strumento è quello di individuare i rischi che hanno una
                        maggiore criticità, alla luce dei controlli già messi in atto e di quelli che si
                        vogliono implementare, per poi successivamente capire quali devono essere monitorati
                        con maggiore attenzione allo scopo di contenere/minimizzare il più possibile le loro
                        conseguenze.
                    </p>
                    <p>
                        Di seguito è riportata la matrice dei rischi, la quale mostra il prodotto tra la
                        probabilità d’insorgenza e l’impatto del rischio che viene definito per semplicità
                        “Rischio”.
                        In base a ogni singolo “Rischio” rilevato, si può stabilire un livello di priorità
                        nella gestione dei controlli.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col className='text-center'>
                    <Image src={riskMatrix} fluid />
                </Col>
            </Row>
        </Container>
    </Section>

    <SingleSectionGray id='gestione'>
        <Col className='text-center heading-section'>
            <h3>Risk addressment</h3>
            <p>Lo scopo di questa fase è quello di gestire i rischi e minimizzare il più possibile le
                conseguenze negative derivanti dal fattore incertezza, vincolando le minacce e
                sfruttando le opportunità.
                Qualsiasi azione compiuta dall'organizzazione volta ad affrontare dei rischi è nota
                come "controllo interno".
            </p>
            <p>
                Durante questa fase, per ogni tipologia di rischio, viene definito il controllo
                specifico che deve essere effettuato al fine di ridurre la criticità.
            </p>
        </Col>
    </SingleSectionGray>

    <SingleSection id='review'>
        <Col className='text-center heading-section'>
            <h3>Risk review and report</h3>
            <p>In questa fase sono monitorati i rischi, principalmente per capire se il profilo degli
                stessi ha subito dei cambiamenti (modifica dei parametri di valutazione della
                probabilità e dell’impatto) oppure verificare se la gestione del rischio è efficace ed
                eventualmente identificare ulteriori misure per contenere i rischi.
            </p>

            <p>Questa fase è parte integrante del SCI e serve a comprovare l'effettiva messa in atto
                del controllo stabilito per ridurre l'impatto del rischio.
            </p>
        </Col>
    </SingleSection>

</Layout>)

export default Rischi
