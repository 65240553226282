import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from 'react-bootstrap'

// import '../styles/sigrid/bootstrap/_variables.scss'
// import '../styles/sigrid/_featureLeft.scss'

const FeatureLeft = props => (
<Col lg={props.md} md={6} sm={12}>
    <div className='feature-left'>
        <span className='icon'>
            <FontAwesomeIcon icon={props.icon} />
        </span>
        <div className='feature-copy'>
            <h3>{props.title}</h3>
            <p>{props.desc}</p>
        </div>
    </div>
</Col>
)

FeatureLeft.propTypes = {
    md: PropTypes.number,
    icon: PropTypes.object,
    title: PropTypes.string,
    desc: PropTypes.string
}

FeatureLeft.defaultProps = {
    md: 4,
    icon: '',
    title: '',
    desc: ''
}

export default FeatureLeft
